import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { CurrencyInput, Label, Select } from "@components";
import { Category, RootProductId } from "@models";
import { ConfigBudgetMaster } from "./types";
import { escapeCategoryId, isYearlyBudget } from "../util";
import { Currency } from "@util";

type Props = {
	productId: RootProductId;
	category: Category;
};

export default function BudgetCategoryAmounts(props: Props) {
	const { productId, category } = props;
	const { control } = useFormContext<ConfigBudgetMaster>();
	const namePrefix = `amounts.${escapeCategoryId(category.id)}`;

	const couponAmountOptions: number[] = [
		5_00, 10_00, 15_00, 20_00, 25_00, 30_00, 35_00, 40_00, 45_00, 50_00,
	];

	const enabledCouponAmounts: number[] = [25_00, 30_00, 50_00];

	return (
		<div data-testid={`budgetCategory-${category.id}`}>
			<FlexCol>
				<FlexRow>
					<Label>
						{isYearlyBudget(category.id)
							? "Max. Betrag/Jahr"
							: productId === "sachbezug"
							? "Betrag/Monat"
							: "Max. Betrag/Monat"}

						{productId === "sachbezug" ? (
							<Controller
								name={`${namePrefix}.maxAmountMonth` as any}
								control={control}
								render={({ field }) => (
									<Select
										id={`${productId}-category-select`}
										{...field}
										onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
											field.onChange(Number(event.target.value));
										}}
									>
										{couponAmountOptions.map((amount) => (
											<option
												key={amount}
												value={amount}
												disabled={!enabledCouponAmounts.includes(amount)}
											>
												{Currency.format(amount)}
											</option>
										))}
									</Select>
								)}
							/>
						) : (
							<Controller
								name={`${namePrefix}.maxAmountMonth` as any}
								control={control}
								render={({ field }) => <CurrencyInput {...field} />}
							/>
						)}
					</Label>
					{category.id !== "sachbezug" && (
						<Label>
							Max. Betrag/Tag
							<Controller
								name={`${namePrefix}.maxAmountDay` as any}
								defaultValue={null}
								control={control}
								render={({ field }) => (
									<CurrencyInput
										placeholder="unbeschränkt"
										{...field}
										allowNull
									/>
								)}
							/>
						</Label>
					)}
				</FlexRow>
			</FlexCol>
		</div>
	);
}

const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
`;

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
`;
